<template>
    <div class="resetPassword">
        <jy-dialog :title="title" :visible.sync="dialogVisible">
            <div>
                <el-form :model="diaform" ref="diaform" :rules="rules" :label-width="formLabelWidth">
                    <el-form-item label="新密码:" prop="password">
                        <el-input v-model="diaform.password" show-password></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码:" prop="newpassword">
                        <el-input v-model="diaform.newpassword" show-password></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </jy-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            title: '重置密码',

            diaform: {
                userId: '',
                password: '',
                newpassword: '',
            },
            dialogVisible: false,
            formLabelWidth: '100px',

            rules: {
                password: [
                    {
                        required: true,
                        message: '请输入新密码',
                        trigger: 'blur',
                    },
                ],
                newpassword: [
                    {
                        required: true,
                        message: '请输入确认密码',
                        trigger: 'blur',
                    },
                ],
            },
        }
    },
    components: {},
    created() {},
    watch: {
        dialogVisible(newview) {
            if (!newview) {
                this.resetForm('diaform')
            }
        },
    },
    methods: {
        // 打开对话框
        init(option) {
            this.dialogVisible = true
            this.diaform.userId = option.userId
        },
        // 重置
        resetForm(formName) {
            this.$nextTick(() => {
                this.$refs[formName].resetFields()
                this.diaform.userId = ''
            })
        },

        cancel() {
            this.dialogVisible = false
            console.log('cancel')
        },
        save() {
            this.$refs.diaform.validate(valid => {
                if (valid) {
                    if (this.diaform.password != this.diaform.newpassword) {
                        this.$message({
                            message: '新密码和确认密码不一致',
                            type: 'error',
                        })
                        return false
                    }
                    let url = '/sys/user/resetPassword'
                    let option = this.diaform
                    this.$http.post(url, option).then(res => {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        })
                        this.dialogVisible = false
                        this.$emit('updateList')
                    })
                } else {
                    return false
                }
            })
        },
    },
}
</script>
<style lang="scss" scoped="resetPassword">
</style>
<style lang="scss">
</style>

