<template>
    <div class="resetPassword">
        <jy-query :model="formInline" ref="queryForm">
            <jy-query-item label="登录账号" prop="userName">
                <el-input v-model="formInline.userName" placeholder="输入账号"></el-input>
            </jy-query-item>
            <jy-query-item label="用户姓名" prop="realName">
                <el-input v-model="formInline.realName" placeholder="输入姓名"></el-input>
            </jy-query-item>
            <jy-query-item label="联系方式" prop="mobile">
                <el-input v-model="formInline.mobile" placeholder="输入联系方式"></el-input>
            </jy-query-item>
            <jy-query-item label="状态" prop="status">
                <el-select v-model="formInline.status" placeholder="请选择">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </jy-query-item>
            <template slot="rightCol">
                <el-button type="primary" @click="oncheck">查询</el-button>
                <el-button type="primary" @click="resetForm('queryForm')">重置</el-button>
                <el-row>
                    <el-button type="primary" @click="add">新增</el-button>
                    <!-- <el-button type="primary" @click="batchDelete">批量删除</el-button> -->
                </el-row>
            </template>
        </jy-query>
        <jy-table :data="lists" @selection-change="batchchange">
            <jy-table-column type="index" width="60" label="序号"></jy-table-column>
            <jy-table-column type="selection" width="70"></jy-table-column>
            <jy-table-column label="用户姓名" prop="realName" min-width="100"></jy-table-column>
            <jy-table-column label="登录账号" prop="userName" min-width="100"></jy-table-column>
            <jy-table-column label="联系方式" prop="mobile" min-width="100"></jy-table-column>
            <jy-table-column label="状态" min-width="100">
                <template slot-scope="scope">
                    {{scope.row.status |change}}
                </template>
            </jy-table-column>
            <jy-operate :width="operateWidth" :list="operateList"></jy-operate>
        </jy-table>
        <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total">
        </jy-pagination>
        <add-dialog ref="dialog" @updateList="oncheck"></add-dialog>
        <reset-dialog ref="resetdialog" @updateList="oncheck"></reset-dialog>
        <application-dialog ref="application" @updateList="oncheck"></application-dialog>
    </div>
</template>
<script>
import adddialog from '@/components/user/adduser'
import resetdialog from '@/components/user/resetPassword'
import applicationDialog from '@/components/user/applicationDistribution'
export default {
    data() {
        return {
            formInline: {
                userName: '',
                realName: '',
                mobile: '',
                status: '',
            },
            lists: [{}],
            options: [
                {
                    value: '',
                    label: '全部',
                },
                {
                    value: '1',
                    label: '启用',
                },
                {
                    value: '0',
                    label: '禁用',
                },
            ],
            // 分页
            pageIndex: 1,
            total: 100,
            pageSize: 10,
            //  操作
            operateList: [],
            operateWidth: '400px',
            //批量删除
            batchDeletearr: [],
        }
    },
    created() {
        this.setOperateList()
        this.getlist()
    },
    components: {
        'add-dialog': adddialog,
        'reset-dialog': resetdialog,
        'application-dialog': applicationDialog,
    },
    filters: {
        change(val) {
            if (val == '0') {
                return '禁用'
            } else {
                return '启用'
            }
        },
    },
    methods: {
        setOperateList() {
            let l = [
                {
                    name: '重置密码',
                    icon: 'el-icon-delete',
                    fun: this.reset,
                },
                {
                    name: '编辑',
                    icon: 'el-icon-edit-outline',
                    fun: this.handleEdit,
                },
                {
                    name: '删除',
                    icon: 'el-icon-delete',
                    fun: this.handleDelete,
                },
                {
                    name: '应用分配',
                    icon: 'el-icon-delete',
                    fun: this.application,
                },
                {
                    name: '启用',
                    icon: 'el-icon-delete',
                    fun: this.enableDisable,
                    isShow(row) {
                        if (row.status == '0') {
                            return true
                        } else {
                            return false
                        }
                    },
                },
                {
                    name: '禁用',
                    icon: 'el-icon-delete',
                    fun: this.enableDisable,
                    isShow(row) {
                        if (row.status == '1') {
                            return true
                        } else {
                            return false
                        }
                    },
                },
            ]
            this.operateList = l
        },
        getlist() {
            let url = '/sys/user/queryPage'
            let option = {
                ...this.formInline,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            }
            this.$http.post(url, option).then(res => {
                console.log(res)
                this.lists = res.detail.list
                this.total = res.detail.total
            })
        },
        // 查询表格
        oncheck() {
            this.pageIndex = 1
            this.getlist()
            console.log('更新')
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.oncheck()
        },
        // 新增
        add() {
            this.$refs.dialog.init()
            console.log('新增')
        },

        // 删除
        handleDelete(row) {
            this.$confirm('是否删除该用户?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let params = []
                    params.push(row.userId)
                    this.deleteRecord(params)
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
            //   console.log(row)
        },
        //批量删除
        batchchange(val) {
            let arr = []
            val.forEach(item => {
                arr.push(item.userId)
            })
            this.batchDeletearr = arr
        },
        batchDelete() {
            if (this.batchDeletearr.length == 0) {
                this.$message({
                    message: '请先选择删除项',
                    type: 'warning',
                })
                return false
            }
            this.$confirm('是否删除选中用户?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.deleteRecord(this.batchDeletearr)
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
        },
        deleteRecord(arr) {
            let url = '/sys/user/delete'
            let option = arr
            this.$http.post(url, option).then(res => {
                this.$message({
                    message: res.msg,
                    type: 'success',
                })
                this.oncheck()
            })
        },
        // 修改
        handleEdit(row) {
            this.$refs.dialog.init(row, 'update')
            console.log('编辑')
        },

        // 分页
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`)
            this.pageSize = val
            this.getlist()
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`)
            this.pageIndex = val
            this.getlist()
        },
        //应用分配
        application(row) {
            this.$refs.application.init(row)
        },
        //重置密码
        reset(row) {
            this.$refs.resetdialog.init(row)
        },
        //启用禁用
        enableDisable(row) {
            let node = '确认要启用吗？'
            if (row.status == '1') {
                node = '确认要禁用吗？'
            }

            this.$confirm(node, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let url = '/sys/user/isEnable'
                    let option = {
                        ...row,
                    }
                    if (row.status == '1') {
                        option.status = '0'
                    } else {
                        option.status = '1'
                    }
                    this.$http.post(url, option).then(res => {
                        console.log(res)
                        this.$message({
                            type: 'success',
                            message: res.msg,
                        })
                        this.oncheck()
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消',
                    })
                })
        },
    },
}
</script>
<style lang="scss" scoped="resetPassword">
.resetPassword {
}
</style>


