<template>
    <div class="addOperation">
        <jy-dialog :title="title" :visible.sync="dialogVisible">
            <div>
                <div class="title">{{appName}}</div>
                <el-tree :props="defaultProps" :data="data" node-key="id" ref="tree" @node-click="clickTree">
                </el-tree>
            </div>
            <jy-dialog :title="innerTitle" :visible.sync="innerVisible" append-to-body>
                <el-tree :props="innerdefaultProps" :data="innerData" ref="tree">
                </el-tree>
            </jy-dialog>
        </jy-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            title: '数据权限',
            appId: '',
            userId: '',
            appName: '',
            data: [],
            defaultProps: {
                children: 'children',
                label: 'text',
                // disabled: function(data) {
                //     return data.id
                // },
            },
            // defaultChecked: [],

            dialogVisible: false,
            formLabelWidth: '100px',

            innerVisible: false,
            innerTitle: '数据权限',
            innerData: [],
            innerdefaultProps: {
                children: 'belong',
                label: 'text',
                // disabled: function(data) {
                //     return data.id
                // },
            },
        }
    },
    components: {},
    created() {},
    watch: {
        dialogVisible(newview) {
            if (!newview) {
                this.resetForm('diaform')
            }
        },
    },
    methods: {
        // 打开对话框
        init(option) {
            this.dialogVisible = true
            this.userId = option.userId
            this.appId = option.appId
            this.appName = option.appName
            this.getlist()
        },
        getlist() {
            let url = '/sys/userApp/queryDataAuthority'
            let option = {
                userId: this.userId,
                appId: this.appId,
            }
            this.$http.post(url, option).then(res => {
                this.data = res.detail
                // this.defaultChecked = res.detail.menuIds
            })
        },
        // 重置
        resetForm(formName) {
            console.log(formName)
            this.data = []
            // this.defaultChecked = []
        },
        clickTree(item) {
            if (item.isBelong == 1) {
                let url = '/sys/userApp/queryDataAuthorityBelong'
                let option = {
                    userId: this.userId,
                    appId: this.appId,
                    orgId: item.id,
                }
                this.$http.post(url, option).then(res => {
                    console.log(res)
                    this.innerData = res.detail
                    this.innerVisible = true
                })
            }
        },
    },
}
</script>
<style lang="scss" scoped="addOperation">
</style>
<style lang="scss">
.addOperation {
    .title {
        font-size: 20px;
        margin-bottom: 10px;
    }
}
</style>


