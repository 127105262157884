<template>
    <div class="adduser">
        <jy-dialog :title="title" :visible.sync="dialogVisible">
            <div>
                <el-form :model="diaform" ref="diaform" :rules="rules" :label-width="formLabelWidth">
                    <el-form-item label="应用名称:" prop="appId" v-if="type== 'add'">
                        <el-select v-model="diaform.appId" placeholder="请选择">
                            <el-option v-for="item in appOptions" :key="item.appId" :label="item.cName" :value="item.appId">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="应用名称:" prop="appName" v-else>
                        {{diaform.appName}}
                    </el-form-item>
                    <div v-if="isshow">
                        <el-form-item label="分配机构:" prop="orgId">
                            <el-cascader ref="cascaderorg" :key="isResouceShow" @change="changeorg" :options="orgOptions" v-model="diaform.orgId" :props="orgProps"></el-cascader>
                        </el-form-item>
                        <el-form-item label="分配角色:" prop="roleIds">
                            <el-select v-model="diaform.roleIds" placeholder="请选择" multiple @change="selectGetFn">
                                <el-option v-for="item in roleOptions" :key="item.roleId" :label="item.roleName" :value="item.roleId">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="用户工种:" prop="userTypeId" v-if="ishavaType">
                            <el-select v-model="diaform.userTypeId" placeholder="请选择">
                                <el-option v-for="item in userOptions" :key="item.userTypeId" :label="item.userTypeName" :value="item.userTypeId">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </jy-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            // 对话框类型
            type: '',
            title: '',
            isshow: true,
            ishavaType: true,

            diaform: {
                userId: '',
                appName: '',
                appId: '',
                frontDisplay: [],
                orgId: [],
                orgName: '',
                roleIds: [],
                roleNames: '',
                userAppId: '',
                userTypeId: '',
            },
            dialogVisible: false,
            formLabelWidth: '100px',

            rules: {
                appId: [
                    {
                        required: true,
                        message: '请选择应用',
                        trigger: 'change',
                    },
                ],
                orgId: [
                    {
                        required: true,
                        message: '请选择机构',
                        trigger: 'change',
                    },
                ],
                roleIds: [
                    {
                        required: true,
                        message: '请选择角色',
                        trigger: 'change',
                    },
                ],
                userTypeId: [
                    {
                        required: true,
                        message: '请选择工种',
                        trigger: 'change',
                    },
                ],
            },
            appOptions: [],
            orgOptions: [],
            roleOptions: [],
            userOptions: [],
            isResouceShow: 0,

            orgProps: {
                checkStrictly: true,
                value: 'id',
                label: 'text',
            },
            isUpdate: true,
        }
    },
    components: {},
    created() {},
    activated() {},
    watch: {
        dialogVisible(newview) {
            if (!newview) {
                this.isshow = true
                this.ishavaType = true
                this.resetForm('diaform')
                this.diaform.userId = ''
                this.orgOptions = []
                this.isResouceShow = this.isResouceShow + 1
                this.roleOptions = []
                this.userOptions = []
                console.log(this.diaform.roleIds)
            }
        },
        'diaform.appId': {
            handler(val) {
                if (val) {
                    if (this.isUpdate) {
                        this.diaform.orgId = []
                        this.diaform.roleIds = []
                    }
                    if (this.type == 'add') {
                        let obj = {}
                        this.appOptions.forEach(item => {
                            if (item.appId == val) {
                                obj = item
                            }
                        })
                        if (obj.appStatistics == '0') {
                            this.isshow = false
                        } else {
                            this.getOrgOption()
                            this.isshow = true
                        }
                        console.log(obj)
                    } else {
                        this.getOrgOption()
                        this.isshow = true
                    }
                    this.roleOptions = []
                }
            },
            immediate: true,
        },
        'diaform.orgId'(val) {
            if (val.length) {
                console.log(val)
                if (this.isUpdate) {
                    this.diaform.roleIds = []
                    this.userTypeId = ''
                }
                this.roleOptions = []
                this.userOptions = []
                this.getRolesOption()
            }
        },
        'diaform.roleIds'(val) {
            if (val.length) {
                let isHave = false
                val.forEach(item => {
                    this.roleOptions.forEach(one => {
                        if (one.roleId == item && one.isService == 1) {
                            isHave = true
                        }
                    })
                })
                if (isHave) {
                    this.ishavaType = true
                    this.diaform.userTypeId = ''
                    this.userOptions = []
                    this.getUserOptions()
                } else {
                    this.ishavaType = false
                    this.diaform.userTypeId = ''
                }
            } else {
                this.ishavaType = false
                this.diaform.userTypeId = ''
            }
        },
    },
    methods: {
        // 打开对话框
        init(option, type) {
            this.type = type || 'add'
            this.dialogVisible = true
            if (this.type == 'update') {
                this.title = '编辑'
                this.isUpdate = false
                this.$nextTick(() => {
                    this.diaform.userId = option.addAppDistributionBO.userId
                    this.diaform.appId = option.addAppDistributionBO.appId
                    this.diaform.appName = option.appName
                    this.diaform.userAppId = option.userAppId
                    let orgArr = option.addAppDistributionBO.frontDisplay.split(
                        ','
                    )
                    orgArr.forEach((item, k) => {
                        orgArr[k] = item
                    })
                    this.diaform.orgId = orgArr
                    this.diaform.roleIds = JSON.parse(
                        JSON.stringify(option.addAppDistributionBO.roleIds)
                    )
                    console.log(this.diaform)
                    // this.getAppOption()
                    this.$nextTick(() => {
                        this.diaform.userTypeId =
                            option.addAppDistributionBO.userTypeId
                        if (this.diaform.userTypeId) {
                            this.ishavaType = true
                            this.getUserOptions()
                        }
                        this.isUpdate = true
                    })
                })
            } else {
                this.diaform.userId = option.userId
                this.title = '新增'
                this.getAppOption()
                this.$nextTick(() => {
                    this.ishavaType = false
                })
            }
        },
        // 重置
        resetForm(formName) {
            console.log(formName)
            this.$nextTick(() => {
                this.$refs[formName].resetFields()
                this.diaform.userId = ''
                this.diaform.appId = ''
                this.diaform.userAppId = ''
            })
        },

        cancel() {
            this.dialogVisible = false
            console.log('cancel')
        },
        save() {
            this.$refs.diaform.validate(valid => {
                if (valid) {
                    let url = ''
                    if (this.type == 'add') {
                        url = '/sys/userApp/appDistribution'
                    } else {
                        url = '/sys/userApp/modifyAppAuthority'
                    }
                    let option = JSON.parse(JSON.stringify(this.diaform))
                    option.frontDisplay = option.orgId.toString()
                    option.orgId = option.orgId[option.orgId.length - 1]
                    this.$http.post(url, option).then(res => {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        })
                        this.dialogVisible = false
                        this.$emit('updateList')
                    })
                } else {
                    return false
                }
            })
        },
        getAppOption() {
            let url = '/sys/userApp/queryNotDistributionApp'
            let option = {
                userId: this.diaform.userId,
            }
            // if (this.diaform.appId) {
            //     option.appId = this.diaform.appId
            // }
            this.$http.post(url, option).then(res => {
                this.appOptions = res.detail
            })
        },
        getOrgOption() {
            let url = '/sys/userApp/queryOrgDistribution'
            let option = {
                appId: this.diaform.appId,
            }
            this.$http.post(url, option).then(res => {
                console.log(res)
                this.isResouceShow = this.isResouceShow + 1
                let filter = function(item) {
                    item.forEach((one, k) => {
                        if (one.children && one.children.length == 0) {
                            item[k].children = null
                        }
                        if (one.children && one.children.length > 0) {
                            filter(one.children)
                        }
                    })
                }
                filter(res.detail)
                this.orgOptions = res.detail
            })
        },

        getRolesOption() {
            let url = '/sys/userApp/queryRoleDistribution'
            let org = ''
            if (this.diaform.orgId.length) {
                org = this.diaform.orgId[this.diaform.orgId.length - 1]
            }
            let option = {
                appId: this.diaform.appId,
                orgId: org,
            }
            this.$http.post(url, option).then(res => {
                console.log(res)
                this.roleOptions = res.detail
            })
        },

        getUserOptions() {
            let url = '/app/info/queryUserType'
            let option = {
                appId: this.diaform.appId,
                type: '1',
            }
            this.$http.post(url, option).then(res => {
                this.userOptions = res.detail
            })
        },
        changeorg() {
            if (this.$refs.cascaderorg.getCheckedNodes()[0]) {
                let label = this.$refs.cascaderorg.getCheckedNodes()[0].label
                this.diaform.orgName = label
            }
        },
        selectGetFn(arr) {
            console.log(arr)
            let labelArr = []
            let role = ''
            if (arr.length) {
                arr.forEach(item => {
                    role = this.roleOptions.find(val => {
                        return val.roleId == item
                    })
                    labelArr.push(role.roleName)
                })
            } else {
                labelArr = []
            }
            labelArr = labelArr.toString()
            console.log(labelArr)
            this.diaform.roleNames = labelArr
        },
    },
}
</script>
<style lang="scss" scoped="adduser">
</style>
<style lang="scss">
</style>

