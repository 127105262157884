<template>
    <div class="adduser">
        <jy-dialog :title="title" :visible.sync="dialogVisible">
            <div>
                <el-form :model="diaform" ref="diaform" :rules="rules" :label-width="formLabelWidth">
                    <el-form-item label="用户姓名:" prop="realName">
                        <el-input v-model="diaform.realName"></el-input>
                    </el-form-item>
                    <el-form-item label="登录账号:" prop="userName">
                        <el-input v-model="diaform.userName"></el-input>
                    </el-form-item>
                    <div v-if="type == 'add'">
                        <el-form-item label="初始密码:" prop="passwd">
                            <el-input v-model="diaform.passwd" show-password></el-input>
                        </el-form-item>
                        <el-form-item label="确认密码:" prop="confirmpw">
                            <el-input v-model="diaform.confirmpw" show-password></el-input>
                        </el-form-item>
                    </div>
                    <el-form-item label="联系方式:" prop="mobile">
                        <el-input v-model="diaform.mobile"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </jy-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            // 对话框类型
            type: '',
            title: '',

            diaform: {
                userId: '',
                realName: '',
                userName: '',
                passwd: '',
                confirmpw: '',
                mobile: '',
            },
            dialogVisible: false,
            formLabelWidth: '100px',

            rules: {
                realName: [
                    {
                        required: true,
                        message: '请输入用户姓名',
                        trigger: 'blur',
                    },
                ],
                userName: [
                    {
                        required: true,
                        message: '请输入登录账号',
                        trigger: 'blur',
                    },
                ],
                passwd: [
                    {
                        required: true,
                        message: '请输入初始密码',
                        trigger: 'blur',
                    },
                ],
                confirmpw: [
                    {
                        required: true,
                        message: '请输入确认密码',
                        trigger: 'blur',
                    },
                ],
                mobile: [
                    {
                        required: true,
                        message: '请输入联系方式',
                        trigger: 'blur',
                    },
                ],
            },
        }
    },
    components: {},
    created() {},
    watch: {
        dialogVisible(newview) {
            if (!newview) {
                this.resetForm('diaform')
            }
        },
    },
    methods: {
        // 打开对话框
        init(option, type) {
            this.type = type || 'add'
            this.dialogVisible = true
            if (this.type == 'update') {
                this.title = '编辑'
                this.$nextTick(() => {
                    this.diaform.userId = option.userId
                    this.diaform.realName = option.realName
                    this.diaform.userName = option.userName
                    this.diaform.mobile = option.mobile
                })
            } else {
                this.title = '新增'
            }
        },
        // 重置
        resetForm(formName) {
            this.$nextTick(() => {
                this.$refs[formName].resetFields()
                this.diaform.userId = ''
            })
        },

        cancel() {
            this.dialogVisible = false
            console.log('cancel')
        },
        save() {
            this.$refs.diaform.validate(valid => {
                if (valid) {
                    let url = ''
                    if (this.type == 'add') {
                        if (this.diaform.passwd != this.diaform.confirmpw) {
                            this.$message({
                                message: '初始密码和确认密码不一致',
                                type: 'error',
                            })
                            return false
                        }
                        url = '/sys/user/save'
                    } else {
                        url = '/sys/user/modify'
                    }
                    let option = this.diaform
                    this.$http.post(url, option).then(res => {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        })
                        this.dialogVisible = false
                        this.$emit('updateList')
                    })
                } else {
                    return false
                }
            })
        },
    },
}
</script>
<style lang="scss" scoped="adduser">
</style>
<style lang="scss">
</style>

