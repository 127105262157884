<template>
    <div class="applicationDistribution">
        <jy-dialog :title="title" :visible.sync="dialogVisible" type="table">
            <jy-query :model="formInline" ref="queryForm">
                <jy-query-item label="应用名称" prop="appName">
                    <el-input v-model="formInline.appName" placeholder="输入应用名称"></el-input>
                </jy-query-item>
                <jy-query-item label="应用类型" prop="appType">
                    <el-select v-model="formInline.appType" placeholder="请选择">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </jy-query-item>
                <jy-query-item label="角色" prop="roleName">
                    <el-input v-model="formInline.roleName" placeholder="输入角色名称"></el-input>
                </jy-query-item>
                <template slot="rightCol">
                    <el-button type="primary" @click="oncheck">查询</el-button>
                    <el-button type="primary" @click="resetForm('queryForm')">重置</el-button>
                    <el-row>
                        <el-button type="primary" @click="add">新增分配</el-button>
                    </el-row>
                </template>
            </jy-query>
            <jy-table :data="lists">
                <jy-table-column type="index" width="60" label="序号"></jy-table-column>
                <jy-table-column label="应用名称" prop="appName" min-width="100"></jy-table-column>
                <jy-table-column label="应用类型" min-width="100">
                    <template slot-scope="scope">
                        {{scope.row.appType | change}}
                    </template>
                </jy-table-column>
                <jy-table-column label="角色" prop="roleName" min-width="100"></jy-table-column>
                <jy-operate :width="operateWidth" :list="operateList"></jy-operate>
            </jy-table>
            <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total">
            </jy-pagination>
        </jy-dialog>
        <add-dialog ref="dialog" @updateList="oncheck"></add-dialog>
        <add-operation ref="operation" @updateList="oncheck"></add-operation>
        <add-dataAccess ref="dataAccess" @updateList="oncheck"></add-dataAccess>
    </div>
</template>
<script>
import adddialog from '@/components/user/addDistribution'
import addOperation from '@/components/user/addOperation'
import addDataAccess from '@/components/user/addDataAccess'
export default {
    data() {
        return {
            // 对话框类型
            userId: '',
            title: '应用分配',

            formInline: {
                appName: '',
                appType: '',
                roleName: '',
            },

            dialogVisible: false,

            lists: [],
            // 分页
            pageIndex: 1,
            total: 100,
            pageSize: 10,
            //  操作
            operateList: [],
            operateWidth: '300px',

            options: [
                {
                    value: '',
                    label: '全部',
                },
                {
                    value: '0',
                    label: 'PC应用',
                },
                {
                    value: '1',
                    label: '移动应用',
                },
            ],
        }
    },
    components: {
        'add-dialog': adddialog,
        'add-operation': addOperation,
        'add-dataAccess': addDataAccess,
    },
    created() {
        this.setOperateList()
    },
    filters: {
        change(val) {
            if (val == '0') {
                return 'PC应用'
            } else {
                return '移动应用'
            }
        },
    },
    watch: {
        dialogVisible(newview) {
            if (!newview) {
                this.resetForm('queryForm')
                this.userId = ''
                this.lists = []
            }
        },
    },
    methods: {
        setOperateList() {
            let l = [
                {
                    name: '编辑',
                    icon: 'el-icon-edit-outline',
                    fun: this.handleEdit,
                    isShow(row) {
                        if (row.appStatistics == '0') {
                            return false
                        } else {
                            return true
                        }
                    },
                },
                {
                    name: '删除',
                    icon: 'el-icon-delete',
                    fun: this.handleDelete,
                },
                {
                    name: '操作权限',
                    icon: 'el-icon-delete',
                    fun: this.operation,
                    isShow(row) {
                        if (row.appStatistics == '0') {
                            return false
                        } else {
                            return true
                        }
                    },
                },
                {
                    name: '数据权限',
                    icon: 'el-icon-delete',
                    fun: this.dataAccess,
                    isShow(row) {
                        if (row.appStatistics == '0') {
                            return false
                        } else {
                            return true
                        }
                    },
                },
            ]
            this.operateList = l
        },
        // 打开对话框
        init(option) {
            this.dialogVisible = true
            this.$nextTick(() => {
                this.userId = option.userId
                this.getlist()
            })
        },
        getlist() {
            let url = '/sys/userApp/queryUserBindAppList'
            let option = {
                ...this.formInline,
                userId: this.userId,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            }
            this.$http.post(url, option).then(res => {
                this.lists = res.detail.list
                this.total = res.detail.total
            })
        },
        // 查询表格
        oncheck() {
            this.pageIndex = 1
            this.getlist()
            console.log('更新')
        },
        // 重置
        resetForm(formName) {
            this.$nextTick(() => {
                this.$refs[formName].resetFields()
                if (this.dialogVisible) {
                    this.oncheck()
                }
            })
        },
        // 分页
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`)
            this.pageSize = val
            this.getlist()
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`)
            this.pageIndex = val
            this.getlist()
        },
        // 新增
        add() {
            this.$refs.dialog.init({ userId: this.userId })
            console.log('新增')
        },
        // 修改
        handleEdit(row) {
            this.$refs.dialog.init(row, 'update')
            console.log('编辑')
        },
        // 删除
        handleDelete(row) {
            this.$confirm('是否删除该应用?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let params = {
                        userId: this.userId,
                        appId: row.appId,
                    }
                    this.deleteRecord(params)
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
            //   console.log(row)
        },
        //批量删除
        batchchange(val) {
            let arr = []
            val.forEach(item => {
                arr.push(item.userId)
            })
            this.batchDeletearr = arr
        },
        batchDelete() {
            if (this.batchDeletearr.length == 0) {
                this.$message({
                    message: '请先选择删除项',
                    type: 'warning',
                })
                return false
            }
            this.$confirm('是否删除选中用户?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.deleteRecord(this.batchDeletearr)
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
        },
        deleteRecord(arr) {
            let url = '/sys/userApp/deleteAppDistribution'
            let option = {
                ...arr,
            }
            this.$http.post(url, option).then(res => {
                this.$message({
                    message: res.msg,
                    type: 'success',
                })
                this.oncheck()
            })
        },
        // 操作权限
        operation(row) {
            let option = {
                ...row,
                userId: this.userId,
            }
            this.$refs.operation.init(option)
        },
        // 数据权限
        dataAccess(row) {
            let option = {
                ...row,
                userId: this.userId,
            }
            this.$refs.dataAccess.init(option)
        },
    },
}
</script>
<style lang="scss" scoped="applicationDistribution">
</style>

