<template>
    <div class="addOperation">
        <jy-dialog :title="title" :visible.sync="dialogVisible">
            <div>
                <div class="title">{{appName}}</div>
                <el-tree :props="defaultProps" :data="data" :default-checked-keys="defaultChecked" node-key="id" show-checkbox ref="tree" @check-change="changeTree">
                </el-tree>
            </div>
        </jy-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            title: '操作权限',
            appId: '',
            userId: '',
            appName: '',
            data: [],
            defaultProps: {
                children: 'children',
                label: 'text',
                disabled: function(data) {
                    return data.id
                },
            },
            defaultChecked: [],

            dialogVisible: false,
            formLabelWidth: '100px',
        }
    },
    components: {},
    created() {},
    watch: {
        dialogVisible(newview) {
            if (!newview) {
                this.resetForm('diaform')
            }
        },
    },
    methods: {
        // 打开对话框
        init(option) {
            this.dialogVisible = true
            this.userId = option.userId
            this.appId = option.appId
            this.appName = option.appName
            this.getlist()
        },
        getlist() {
            let url = '/sys/userApp/queryOperationAuthority'
            let option = {
                userId: this.userId,
                appId: this.appId,
            }
            this.$http.post(url, option).then(res => {
                this.data = res.detail.menuTreeBeans
                this.defaultChecked = res.detail.menuIds
            })
        },
        // 重置
        resetForm(formName) {
            console.log(formName)
            this.data = []
            this.defaultChecked = []
        },
        changeTree() {
            // console.log(1)
            // let t = this.$refs.tree.getCheckedNodes()
            // console.log(t)
        },
    },
}
</script>
<style lang="scss" scoped="addOperation">
</style>
<style lang="scss">
.addOperation {
    .title {
        font-size: 20px;
        margin-bottom: 10px;
    }
}
</style>


